import React from 'react'
import type { QueryDownloadEmissionsReportArgs } from 'src/generated/graphql/types'
import type { OngoingExportRequest } from 'src/provider/EmissionsExportDownloadProvider'

export const EmissionsExportDownloadContext = React.createContext<{
  ongoingExportRequests: OngoingExportRequest[]
  isAnOngoingExportRequest: boolean
  sendEmissionsExportRequest: (
    input: Omit<QueryDownloadEmissionsReportArgs['exportDashboardInput'], 'frontendRequestId'>
  ) => Promise<void>
}>({
  ongoingExportRequests: [],
  isAnOngoingExportRequest: false,
  sendEmissionsExportRequest: async () => {}
})
