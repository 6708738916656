import type { ThemeColorsType } from 'src/app/theme/type'
import { getDesignColors } from 'src/app/theme/common'

const primary = {
  100: '#E6F3F0',
  200: '#B0D8D1',
  300: '#8AC6BB',
  400: '#55AB9C',
  500: '#349B89',
  600: '#01826B',
  700: '#015C4C',
  800: '#01483B',
  900: '#01483B',
  1000: '#002720'
}

export const colors: ThemeColorsType = {
  warning: '#ef9e3d',
  primary: '#01826b',
  primaryOnDark: '#6bc4aa',
  error: '#98153b',
  errorOnDark: '#c17389',
  info: '#1061c2',
  darkgreenDarker: '#01201a',
  darkgreenBase: '#00372d',
  darkgreenLighter1: '#01826b',
  darkgreenLighter2: '#01b292',
  lightgreenDarker: '#099d72',
  lightgreenBase: '#0bd89d',
  lightgreenLighter1: '#67eac4',
  lightgreenLighter2: '#cbfdef',
  lightgreenLighter3: '#0bd89d1a',
  lightgreenLighter4: '#099d720d',
  purpleDarker: '#120f23',
  purpleBase: '#2b225a',
  purpleLighter1: '#484073',
  purpleLighter2: '#78719e',
  purpleLighter3: '#a29dbc',
  purpleLighter4: '#f1f0f3',
  sandDarker: '#ef9e3d',
  sandBase: '#ffcf97',
  sandLighter1: '#ffe5c6',
  sandLighter2: '#fff4e6',
  greengrey80: '#101414',
  greengrey70: '#191f1d',
  greengrey60: '#212927',
  greengrey50: '#293331',
  greengrey40: '#5f6664',
  greengrey30: '#949998',
  greengrey20: '#c9cccb',
  greengrey10: '#dfe0e0',
  greengrey8: '#eeefef',
  greengrey5: '#f5f5f5',
  greengrey2: '#fbfbfb',
  background: '#fff',
  white: '#fff',
  ...getDesignColors({ primary })
}

export const images = {
  logoUrl: '/images/logo.svg',
  compactLogoUrl: '/images/logo-mini.svg'
}
