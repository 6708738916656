import { Language } from 'src/generated/graphql/types'
import { createContext } from 'react'

type OverrideLanguageStore = {
  overridedLanguage: Language | null
  setOverridedLanguage: (language: Language) => void
}

export const OverrideLanguageContext = createContext<OverrideLanguageStore>({
  overridedLanguage: null,
  setOverridedLanguage: () => {}
})
