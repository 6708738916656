import type { Locale } from 'antd/lib/locale'
import antFrLocale from 'antd/locale/fr_FR'
import antDeLocale from 'antd/locale/de_DE'
import antEnLocale from 'antd/locale/en_GB'
import antEsLocale from 'antd/locale/es_ES'

export const antdLocales: { [key: string]: Locale } = {
  fr: antFrLocale,
  de: antDeLocale,
  en: antEnLocale,
  es: antEsLocale
}
