import React, { type PropsWithChildren, useEffect } from 'react'
import { setTwoToneColor } from '@ant-design/icons'
import { ConfigProvider } from 'antd'
import antEnLocale from 'antd/locale/en_GB'
import { useTypedTranslation } from 'src/common/utils'
import { appTheme } from 'src/app/theme/theme'
import kebabCase from 'lodash/fp/kebabCase'
import type { ThemeColorsType } from 'src/app/theme/type'
import { antdLocales } from 'src/app/antdLocales'

type GenericAntConfigProviderProps = PropsWithChildren & Readonly<{ colors: ThemeColorsType }>

export function AntConfigProvider({ children, colors }: GenericAntConfigProviderProps) {
  const { i18n } = useTypedTranslation()

  useEffect(() => {
    setTwoToneColor(colors.lightgreenBase)
    const root = document.documentElement
    Object.entries(colors).forEach(([key, value]) => {
      root.style.setProperty(`--theme-color-${kebabCase(key)}`, value)
    })
  }, [])

  return (
    <ConfigProvider locale={antdLocales[i18n.language] ?? antEnLocale} theme={appTheme(colors)}>
      {children}
    </ConfigProvider>
  )
}
