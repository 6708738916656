import { Select } from 'antd'
import i18next, { t } from 'i18next'
import { useState } from 'react'
import { useTypedTranslation } from '../common/utils'

export function PolicyViewer(props: Readonly<{ i18nKey: string; path: string; pdfFilename: string }>) {
  const [language, setLanguage] = useState<'en' | 'fr'>(i18next.language == 'fr' ? 'fr' : 'en')

  const { i18nKey, path, pdfFilename } = props

  const { t: typedT } = useTypedTranslation()
  return (
    <div className="h-screen flex flex-col">
      <div className="shrink flex flex-row items-center px-8 py-4">
        <div>
          <h3 className="text-lg font-bold mb-0">{t(i18nKey)}</h3>
        </div>
        <div className="grow" />
        <Select popupMatchSelectWidth={false} className="w-48" value={language} onChange={setLanguage}>
          <Select.Option value="en">{typedT('policies.languages.en')}</Select.Option>
          <Select.Option value="fr">{typedT('policies.languages.fr')}</Select.Option>
        </Select>
      </div>
      <iframe src={`${import.meta.env.BASE_URL}policies/${path}/${language}/${pdfFilename}`} className="grow" />
    </div>
  )
}
