import { useAreFileImportFlowsWaitingForProcessingOrInProgressLazyQuery } from 'src/generated/graphql/types'
import { StickyMessage } from 'src/common/designSystem/StickyMessage'
import React, { useEffect, useRef, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useTypedTranslation } from 'src/common/utils'
import { useLocation } from 'react-router'
import { Typography } from 'antd'

const INCLUDED_PATHS = [
  '/dataCollection',
  '/imports_v2/flow',
  '/mappings',
  '/analyses/dashboards',
  '/trajectory',
  '/actionPlans'
]

type FlowStatusCheckerProps = Readonly<{
  pollInterval?: number
  alwaysPoll?: boolean
  showMessage?: boolean
}>

export function FlowStatusChecker({
  pollInterval = 5000,
  alwaysPoll = false,
  showMessage = true
}: FlowStatusCheckerProps) {
  const { t } = useTypedTranslation()
  const [state, setState] = useState({
    shouldResetCache: false,
    hasPendingFlows: false
  })
  const client = useApolloClient()
  const { pathname } = useLocation()
  const timeout = useRef<NodeJS.Timeout>(null)

  const [checkPendingFlows] = useAreFileImportFlowsWaitingForProcessingOrInProgressLazyQuery({
    fetchPolicy: 'no-cache'
  })

  const refetchQuery = async () => {
    await checkPendingFlows({
      onCompleted: ({ areFileImportFlowsWaitingForProcessingOrInProgress }) => {
        if (timeout.current) {
          clearTimeout(timeout.current)
          timeout.current = null
        }
        setState(prevState => ({
          shouldResetCache: prevState.shouldResetCache || areFileImportFlowsWaitingForProcessingOrInProgress,
          hasPendingFlows: areFileImportFlowsWaitingForProcessingOrInProgress
        }))
        if (alwaysPoll || areFileImportFlowsWaitingForProcessingOrInProgress) {
          timeout.current = setTimeout(refetchQuery, pollInterval)
        }
      }
    })
  }

  useEffect(() => {
    if (!state.hasPendingFlows && state.shouldResetCache) {
      client.cache.evict({ fieldName: 'listContributingSources' })
      client.cache.evict({ fieldName: 'listMetrics' })
      client.cache.evict({ fieldName: 'listMetricData' })
      client.cache.evict({ fieldName: 'aggregateEmissions' })
      client.cache.evict({ fieldName: 'aggregateEmissionsPerIndicatorPerContributingEntity' })
      client.cache.evict({ fieldName: 'getTopEmissionsByContributingSourceName' })
      client.cache.evict({ fieldName: 'forecastTrajectory' })
      client.cache.evict({ fieldName: 'forecastActionPlanInTrajectory' })
      client.cache.evict({ fieldName: 'listMappingTables' })
      client.cache.evict({ fieldName: 'listMappingTableRows' })
      client.cache.gc()
      setState({ shouldResetCache: false, hasPendingFlows: false })
    }
  }, [state])

  useEffect(() => {
    if (INCLUDED_PATHS.find(path => pathname.includes(path))) {
      refetchQuery()
    }
  }, [pathname])

  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current)
    }
  }, [])

  if (!state.hasPendingFlows || !showMessage) return null
  return (
    <StickyMessage>
      <Typography.Text>{t('common.data_processing')}</Typography.Text>
    </StickyMessage>
  )
}
