import { ApolloProvider } from '@apollo/client'
import { ConfigProvider } from 'antd'
import antEnLocale from 'antd/locale/en_GB'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/de'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useEffect } from 'react'
import { Route, Routes } from 'react-router'
import { getAnonymousApolloClient } from 'src/common/apollo/apolloClient'
import { useTypedTranslation } from 'src/common/utils'
import { SharedLCAPresets } from 'src/modules/shared/lcaTemplates/SharedLCAPresets'
import 'src/App.css'
import { antdLocales } from 'src/app/antdLocales'

dayjs.extend(isBetween)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(localizedFormat)

export function SharedContainer() {
  const { i18n } = useTypedTranslation()

  useEffect(() => {
    dayjs.locale(i18n.language)
  }, [i18n.language])

  return (
    <div className="App">
      <ApolloProvider client={getAnonymousApolloClient()}>
        <ConfigProvider locale={antdLocales[i18n.language] ?? antEnLocale}>
          <Routes>
            <Route path="lcaTemplates">
              <Route path="presets" element={<SharedLCAPresets />} />
            </Route>
          </Routes>
        </ConfigProvider>
      </ApolloProvider>
    </div>
  )
}
