import React, { type ReactNode } from 'react'
import { useTypedTranslation } from 'src/common/utils'
import classNames from 'classnames'
import { Button, Tag, Tooltip } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { CategoryDisplay, type Size } from 'src/common/designSystem/labels/category/CategoryDisplay'
import { CategoryPopover } from './CategoryPopover'
import { useTheme } from 'src/app/theme/useTheme'

export type CategoryLabel = {
  id: string
  name: string
  color?: string
  iconName?: string
  description?: string | null
  reportingCategory?: { id: string; name: string; scope: number } | null
}

export type CategoryLabelProps = Readonly<{
  category: CategoryLabel
  label?: string | ReactNode
  withBackground?: boolean
  multiple?: boolean
  onPick?: (category: CategoryLabel) => void
  onDelete?: (category: CategoryLabel) => void
  hidePickButton?: boolean
  size?: Size
  asTag?: boolean
  hideReportingCategory?: boolean
  maxLength?: number
}>

export const CategoryLabel = React.memo(
  ({
    label,
    category,
    withBackground = false,
    multiple,
    onPick,
    onDelete,
    hidePickButton = false,
    asTag = false,
    hideReportingCategory = false,
    size = 'normal',
    maxLength
  }: CategoryLabelProps) => {
    const { t } = useTypedTranslation()
    const { colors } = useTheme()
    const reportingCategory = category.reportingCategory
    const labelToShow = label ?? category.name

    return (
      <div
        className={classNames('flex items-center gap-2 group', {
          'px-1 py-0.5 rounded-sm bg-greengrey-2': withBackground,
          'px-1 py-0.5 rounded-sm bg-greengrey-5': asTag
        })}
      >
        {!onPick && !onDelete && category.name !== t('common.not_applicable') ? (
          <CategoryPopover visible category={category}>
            <div className="flex items-center gap-2">
              <CategoryDisplay category={category} size={size} />
              <div className="leading-tight truncate">
                {typeof labelToShow === 'string' && maxLength && labelToShow.length > maxLength
                  ? `${labelToShow.substring(0, maxLength)}...`
                  : labelToShow}
              </div>
            </div>
          </CategoryPopover>
        ) : (
          <div className="flex items-center gap-2">
            <CategoryDisplay category={category} size={size} />
            <div className="leading-tight truncate">
              {typeof labelToShow === 'string' && maxLength && labelToShow.length > maxLength
                ? `${labelToShow.substring(0, maxLength)}...`
                : labelToShow}
            </div>
          </div>
        )}
        {!hideReportingCategory && reportingCategory && (
          <Tooltip title={reportingCategory.name}>
            <Tag>{t('categories.scope', { scope: reportingCategory.scope })}</Tag>
          </Tooltip>
        )}
        {asTag && (
          <Button
            className="rounded-sm"
            icon={<CloseOutlined style={{ width: 12, color: colors.greengrey40 }} />}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              onDelete?.(category)
            }}
            size="small"
            type="text"
          />
        )}
        {multiple && !hidePickButton && (
          <div className="hidden group-hover:inline-flex absolute right-2">
            <Button
              size="small"
              className="rounded-sm text-xs h-[20px] p-1"
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                onPick?.(category)
              }}
            >
              {t('categories.pick')}
            </Button>
          </div>
        )}
      </div>
    )
  }
)
