import dayjs from 'dayjs'
import { DurationType, type PeriodTypeWithDate } from 'src/common/designSystem/PeriodSelect/PeriodSelect.types'
import { UnsupportedValueError } from 'src/common/utils/UnsupportedValueError'
import { FiscalYearType, PeriodType } from 'src/generated/graphql/types'

export const getYearStart = (year: number) => dayjs(`${year}-01-01`)

export const getPeriodTypeWithDate = (
  year: number,
  period: PeriodType, // Y, S1, S2, Q1, Q2, Q3, Q4, M1, M2, ..., M12
  fiscalYearType: FiscalYearType, // FiscalYearN or FiscalYearNMinusOne
  firstReportingMonth: number // 1-12
): PeriodTypeWithDate => {
  const baseYear = fiscalYearType === FiscalYearType.FiscalYearN ? year : year - 1
  const fiscalYearStart = dayjs(new Date(baseYear, firstReportingMonth - 1, 1))

  if (period === 'Y') {
    const end = fiscalYearStart.add(1, 'year').subtract(1, 'day')
    return {
      period,
      start: fiscalYearStart.toDate(),
      end: end.toDate()
    }
  }
  if (period === 'S1' || period === 'S2') {
    const num = parseInt(period[1]!, 10)
    const startS = fiscalYearStart.add((num - 1) * 6, 'month')
    const end = startS.add(6, 'month').subtract(1, 'day')
    return {
      period,
      start: startS.toDate(),
      end: end.toDate()
    }
  }
  if (period === 'Q1' || period === 'Q2' || period === 'Q3' || period === 'Q4') {
    const num = parseInt(period[1]!, 10)
    const startQ = fiscalYearStart.add((num - 1) * 3, 'month')
    const end = startQ.add(3, 'month').subtract(1, 'day')
    return {
      period,
      start: startQ.toDate(),
      end: end.toDate()
    }
  }
  if (
    period === 'M1' ||
    period === 'M2' ||
    period === 'M3' ||
    period === 'M4' ||
    period === 'M5' ||
    period === 'M6' ||
    period === 'M7' ||
    period === 'M8' ||
    period === 'M9' ||
    period === 'M10' ||
    period === 'M11' ||
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    period === 'M12'
  ) {
    const num = parseInt(period.slice(1), 10)
    const startM = fiscalYearStart.add(num - 1, 'month')
    const end = startM.add(1, 'month').subtract(1, 'day')
    return {
      period,
      start: startM.toDate(),
      end: end.toDate()
    }
  }
  throw new UnsupportedValueError(period)
}
export const getPeriods = (
  year: number,
  duration: DurationType,
  fiscalYearType: FiscalYearType,
  firstReportingMonth: number
): PeriodTypeWithDate[] => {
  switch (duration) {
    case DurationType.Year: {
      return [getPeriodTypeWithDate(year, PeriodType.Y, fiscalYearType, firstReportingMonth)]
    }
    case DurationType.Semester: {
      // Two half‐year periods
      return [
        getPeriodTypeWithDate(year, PeriodType.S1, fiscalYearType, firstReportingMonth),
        getPeriodTypeWithDate(year, PeriodType.S2, fiscalYearType, firstReportingMonth)
      ]
    }
    case DurationType.Quarter: {
      // Four quarter periods
      return [
        getPeriodTypeWithDate(year, PeriodType.Q1, fiscalYearType, firstReportingMonth),
        getPeriodTypeWithDate(year, PeriodType.Q2, fiscalYearType, firstReportingMonth),
        getPeriodTypeWithDate(year, PeriodType.Q3, fiscalYearType, firstReportingMonth),
        getPeriodTypeWithDate(year, PeriodType.Q4, fiscalYearType, firstReportingMonth)
      ]
    }
    case DurationType.Month: {
      // Twelve month periods
      return Array.from({ length: 12 }, (_, i) => {
        return getPeriodTypeWithDate(year, `M${i + 1}` as PeriodType, fiscalYearType, firstReportingMonth)
      })
    }
    default:
      return duration satisfies never[]
  }
}

const durationsMap = new Map<string, DurationType>([
  ['Y', DurationType.Year],
  ['S', DurationType.Semester],
  ['Q', DurationType.Quarter],
  ['M', DurationType.Month]
])
// 'M10' => Month
export const getDurationTypeFromPeriodType = (periodType: PeriodType): DurationType => durationsMap.get(periodType[0]!)!
