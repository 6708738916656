import type { PeriodType } from 'src/generated/graphql/types'

export enum DurationType {
  Year = 'Year',
  Semester = 'Semester',
  Quarter = 'Quarter',
  Month = 'Month'
}
export type PeriodTypeWithDate = {
  period: PeriodType
  start: Date
  end: Date
}
