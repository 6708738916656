import ReactECharts, { type EChartsOption } from 'echarts-for-react'
import { generateRGBColorGradient, useTypedTranslation } from 'src/common/utils'
import { toFormattedNumber } from 'src/modules/analysis/dashboards/_hooks'
import { estimateFootprint } from 'src/modules/lcas/templateDetails/presets/computation/footprintEstimator'

type HorizontalBarChartPreset = {
  resolvedComponents: {
    id: string
    value: number
    variant: {
      impactComponent: {
        id: string
        name: string
        color: string
        unitEntity: {
          conversionFactor: number
        }
      }
      name: string
      emissionFactor: {
        valuePerReferenceUnit: number
        referenceUnitEntity: {
          conversionFactor: number
        }
      }
    }
  }[]
}

export function HorizontalBarChart(props: {
  readonly preset: HorizontalBarChartPreset
  readonly maxTotalFootprintKgCO2e?: number
}) {
  const { preset, maxTotalFootprintKgCO2e } = props

  const { t, i18n } = useTypedTranslation()

  // Naive way to find the best ceiling value
  let maxCeilingValue = 1
  let maxTotalFootprintKgCO2eRounded: number | undefined
  if (maxTotalFootprintKgCO2e) {
    maxTotalFootprintKgCO2eRounded = maxTotalFootprintKgCO2e
    while (maxTotalFootprintKgCO2eRounded > 100) {
      maxTotalFootprintKgCO2eRounded /= 10
      maxCeilingValue *= 10
    }
  }

  const resolvedComponentIdColorMap = generateResolveComponentIdColorMap(preset.resolvedComponents)

  function shouldShowLabel(value: number) {
    if (maxTotalFootprintKgCO2e === 0 || !maxTotalFootprintKgCO2e) {
      return true
    }
    // Hide labels that have not enough space to display
    return Math.abs(value / maxTotalFootprintKgCO2e) > 0.05
  }

  const chartOptions: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow-sm' // 'shadow-sm' as default; can also be 'line' or 'shadow-sm'
      }
    },
    legend: false,
    grid: {
      left: '3%',
      right: '4%',
      top: '10%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      max: maxTotalFootprintKgCO2e ? Math.ceil(maxTotalFootprintKgCO2e / maxCeilingValue) * maxCeilingValue : undefined
    },
    yAxis: {
      type: 'category',
      data: [t('lca_templates.presets.details.chart_title')]
    },
    series: preset.resolvedComponents.map(resolvedComponent => ({
      name: `${resolvedComponent.variant.impactComponent.name} - ${resolvedComponent.variant.name}`,
      type: 'bar',
      itemStyle: {
        color: resolvedComponentIdColorMap.get(resolvedComponent.id)
      },
      tooltip: {
        valueFormatter: function (value: number) {
          return toFormattedNumber(value, i18n.language)
        }
      },
      stack: 'total',
      label: {
        show: true,
        formatter: function (params: any) {
          if (!shouldShowLabel(params.data)) {
            return ''
          }
          return toFormattedNumber(params.data, i18n.language)
        }
      },
      emphasis: {
        focus: 'series'
      },
      data: [
        estimateFootprint({
          value: resolvedComponent.value,
          impactFactor: resolvedComponent.variant.emissionFactor,
          component: resolvedComponent.variant.impactComponent
        })
      ]
    }))
  }

  return (
    <div className="w-full relative">
      <ReactECharts className="h-24 sm:h-48" option={chartOptions} notMerge />
    </div>
  )
}

function generateResolveComponentIdColorMap(
  resolvedComponents: HorizontalBarChartPreset['resolvedComponents']
): Map<string, string> {
  const impactComponentIdColorAndCountMap = new Map<string, { count: number; color: string }>()
  resolvedComponents.forEach(resolvedComponent => {
    const component = impactComponentIdColorAndCountMap.get(resolvedComponent.variant.impactComponent.id)
    impactComponentIdColorAndCountMap.set(resolvedComponent.variant.impactComponent.id, {
      count: (component?.count ?? 0) + 1,
      color: resolvedComponent.variant.impactComponent.color
    })
  })
  const impactComponentIdColorsMap = new Map<string, string[]>()
  impactComponentIdColorAndCountMap.forEach((component, impactComponentId) => {
    impactComponentIdColorsMap.set(
      impactComponentId,
      generateRGBColorGradient({ hexReferenceColor: component.color, length: component.count })
    )
  })

  const returnMap = new Map<string, string>()
  const countPerImpactComponent = new Map<string, number>()
  resolvedComponents.forEach(resolvedComponent => {
    // Index of the component in the list of components with the same impact component
    const impactComponentIndex = countPerImpactComponent.get(resolvedComponent.variant.impactComponent.id) ?? 0

    // Get the color list for the component
    const colors = impactComponentIdColorsMap.get(resolvedComponent.variant.impactComponent.id) ?? []

    returnMap.set(resolvedComponent.id, colors[impactComponentIndex]!)

    // Update index for the next resolved component with the same impact component
    countPerImpactComponent.set(resolvedComponent.variant.impactComponent.id, impactComponentIndex + 1)
  })
  return returnMap
}
