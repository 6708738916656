import {
  ApiOutlined,
  CarryOutOutlined,
  FormOutlined,
  AuditOutlined,
  DashboardOutlined,
  TableOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { OrganizationLink } from '../OrganizationLink'
import {
  CAMPAIGNS_ROUTE,
  CONNECT_ROUTE,
  IMPORTS_ROUTE,
  DATA_COLLECTION_ROUTE,
  MAPPINGS_ROUTE,
  MEASURE_MENU,
  QUESTIONNAIRES_ROUTE,
  MANUAL_ENTRIES_ROUTE
} from '../routes'
import { usePermissions } from './index'
import { type GetProp, Menu, Tooltip } from 'antd'
import { useListMappingTablesQuery } from 'src/generated/graphql/types'

export const useMeasureMenu = (openKeys: string[]) => {
  const { t } = useTypedTranslation()
  const { data } = useListMappingTablesQuery({
    variables: { pagination: { limit: 1 }, filter: { withUnpublishedChanges: true } }
  })
  const isMappingWarning = data?.listMappingTables.totalCount !== undefined && data.listMappingTables.totalCount > 0
  const {
    canReadAnalyses,
    canReadUpload,
    permissionsLoading,
    canManageCampaigns,
    canAssignCampaignTask,
    canUploadFile
  } = usePermissions()
  const isCampaignsRelease = useOverridableFlag(UnleashFlag.ReleaseCampaigns)
  const isReleaseCampaignsEnabled = useOverridableFlag(UnleashFlag.ReleaseCampaigns)
  const isConnectV1Enabled = useOverridableFlag(UnleashFlag.ConnectV1)
  const isConnectV2Enabled = useOverridableFlag(UnleashFlag.ConnectV2)
  const isManualEntriesEnabled = useOverridableFlag(UnleashFlag.ManualEntries)

  if (permissionsLoading) return null
  if (!canReadAnalyses && !canReadUpload && !canUploadFile) return null

  const children: GetProp<typeof Menu, 'items'> = []
  if (canReadAnalyses) {
    children.push({
      key: `${MEASURE_MENU} ${DATA_COLLECTION_ROUTE}/*`,
      icon: <AuditOutlined />,
      label: (
        <OrganizationLink to="/dataCollection">
          {isCampaignsRelease ? t('menu.analyses') : t('menu.data_collection')}
        </OrganizationLink>
      )
    })
  }

  if (isCampaignsRelease) {
    if (canManageCampaigns) {
      children.push({
        key: `${MEASURE_MENU} ${CAMPAIGNS_ROUTE}/*`,
        icon: <CarryOutOutlined />,
        label: <OrganizationLink to="/campaigns">{t('menu.campaigns')}</OrganizationLink>
      })
      children.push({
        key: `${MEASURE_MENU} ${QUESTIONNAIRES_ROUTE}/*`,
        icon: <FormOutlined />,
        label: <OrganizationLink to="/questionnaires">{t('menu.questionnaires')}</OrganizationLink>
      })
    } else if (canAssignCampaignTask) {
      children.push({
        key: `${MEASURE_MENU} ${CAMPAIGNS_ROUTE}/*`,
        icon: <CarryOutOutlined />,
        label: <OrganizationLink to="/campaigns">{t('menu.campaigns')}</OrganizationLink>
      })
    }
  }

  if (isConnectV1Enabled && canReadUpload) {
    children.push({
      key: `${MEASURE_MENU} ${CONNECT_ROUTE}/*`,
      icon: <ApiOutlined />,
      label: <OrganizationLink to="/connect">{t('menu.connect')}</OrganizationLink>
    })
  }

  if (isConnectV2Enabled && canUploadFile) {
    children.push({
      key: `${MEASURE_MENU} ${IMPORTS_ROUTE}/*`,
      icon: <ApiOutlined />,
      label: <OrganizationLink to="/imports_v2">{`${t('menu.imports')}`}</OrganizationLink>
    })
  }

  if (isManualEntriesEnabled)
    children.push({
      key: `${MEASURE_MENU} ${MANUAL_ENTRIES_ROUTE}/*`,
      icon: <TableOutlined />,
      label: <OrganizationLink to="/manualEntries">{`${t('menu.manual_entries')}`}</OrganizationLink>
    })

  if ((isReleaseCampaignsEnabled && canManageCampaigns) || isConnectV2Enabled) {
    children.push({
      key: `${MEASURE_MENU} ${MAPPINGS_ROUTE}`,
      icon: <TableOutlined />,
      label: (
        <Tooltip title={isMappingWarning ? t('mappings.menu_warning') : ''}>
          <OrganizationLink to="/mappings">
            {t('menu.mappings')}
            {isMappingWarning && <ExclamationCircleOutlined className="text-warning ml-2" />}
          </OrganizationLink>
        </Tooltip>
      )
    })
  }

  return {
    key: MEASURE_MENU,
    icon: <DashboardOutlined />,
    label: (
      <>
        {t('menu.measure')}
        {isMappingWarning && !openKeys.includes(MEASURE_MENU) && (
          <Tooltip title={t('mappings.menu_warning')}>
            <ExclamationCircleOutlined className=" ml-2 text-warning" />
          </Tooltip>
        )}
      </>
    ),
    children
  }
}
