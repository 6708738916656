import { type CSSProperties } from 'react'
import { secondary2Color } from 'src/common/utils'

export function FootprintRatioBar(props: {
  readonly ratio: number
  readonly style?: CSSProperties
  readonly className?: string
}) {
  const { ratio } = props

  return (
    <div className={`relative bg-white rounded-sm ${props.className ?? ''}`} style={{ height: '20px', ...props.style }}>
      <div
        className="absolute inset-0 rounded-sm h-full transition-all"
        style={{
          width: `${ratio * 100}%`,
          backgroundColor: secondary2Color
        }}
      />
    </div>
  )
}
