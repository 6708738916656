import { useCurrentOrganizationWelcomePageQuery } from 'src/generated/graphql/types'
import CenteredLoader from '../common/utils/CenteredLoader'
import Container from '../common/utils/Container'
import { useTypedTranslation } from '../common/utils'
import { TraaceEditor } from '../common/editor'

import { useMessageAPI } from 'src/provider/useMessageAPI'

export default function WelcomePage() {
  const { t } = useTypedTranslation()
  const message = useMessageAPI()
  const { loading, error, data } = useCurrentOrganizationWelcomePageQuery()

  if (loading) {
    return <CenteredLoader />
  }

  if (error || !data) {
    console.error(error)
    message.error(t('common.error_message'))
    return null
  }

  return (
    <Container>
      <div className="bg-white border rounded-sm p-4">
        <TraaceEditor data={data.currentOrganization.welcomePageContents} readOnly />
      </div>
    </Container>
  )
}
