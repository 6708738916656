import { useContext } from 'react'
import { OverrideLanguageContext } from 'src/app/languages/overrideLanguage/OverrideLanguageContext'

export const useOverrideLanguage = () => {
  const context = useContext(OverrideLanguageContext)
  return {
    overridedLanguage: context.overridedLanguage,
    setOverridedLanguage: context.setOverridedLanguage
  }
}
