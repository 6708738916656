import type { ColorPalette, ThemeColorsType } from 'src/app/theme/type'
import { getDesignColors } from 'src/app/theme/common'

// TODO correct
const primary: ColorPalette = {
  100: '#e9ebef',
  200: '#babfcc',
  300: '#98a1b4',
  400: '#6a7692',
  500: '#4d5b7d',
  600: '#20325c',
  700: '#1d2e54',
  800: '#172441',
  900: '#121c33',
  1000: '#0d1527'
}

export const colors: ThemeColorsType = {
  warning: '#ef9e3d',
  primary: '#20325c',
  primaryOnDark: '#6bc4aa',
  error: '#98153b',
  errorOnDark: '#c17389',
  info: '#1061c2',
  darkgreenDarker: '#314c8c',
  darkgreenBase: '#20325c',
  darkgreenLighter1: '#4063b6',
  darkgreenLighter2: '#224087',
  lightgreenDarker: '#265fdf',
  lightgreenBase: '#628cec',
  lightgreenLighter1: '#628CECFF',
  lightgreenLighter2: '#cbfdef',
  lightgreenLighter3: '#0bd89d1a',
  lightgreenLighter4: '#099d720d',
  purpleDarker: '#120f23',
  purpleBase: '#2b225a',
  purpleLighter1: '#484073',
  purpleLighter2: '#78719e',
  purpleLighter3: '#a29dbc',
  purpleLighter4: '#f1f0f3',
  sandDarker: '#ef9e3d',
  sandBase: '#ffcf97',
  sandLighter1: '#ffe5c6',
  sandLighter2: '#fff4e6',
  greengrey80: '#101414',
  greengrey70: '#191f1d',
  greengrey60: '#212927',
  greengrey50: '#293331',
  greengrey40: '#5f6664',
  greengrey30: '#949998',
  greengrey20: '#c9cccb',
  greengrey10: '#dfe0e0',
  greengrey8: '#eeefef',
  greengrey5: '#f5f5f5',
  greengrey2: '#fbfbfb',
  background: '#fff',
  white: '#fff',
  ...getDesignColors({ primary })
}

export const images = {
  logoUrl: '/images/logo_tennaxia.svg',
  compactLogoUrl: '/images/logo_tennaxia_mini.svg'
}
